const rules = {
	superv: {
		static: [
	        "tickets-manager:showall",
		    "user-modal:editQueues",
		    "ticket-options:transferWhatsapp",
		    "contacts-page:viewer",
		],
	},

	admin: {
		static: [
			"drawer-admin-items:view",
			"tickets-manager:showall",
			"user-modal:editProfile",
			"user-modal:editQueues",
			"ticket-options:deleteTicket",
			"ticket-options:transferWhatsapp",
			"contacts-page:deleteContact",
		],
	},
	user: {
		static: [
		],
	},
};

export default rules;
